import React from "react"
import Layout from './../components/shared/Layout';
import ContactContent from './../components/contact/ContactContent';

const ContactPage = () => (
  <Layout title="Contact">
      <ContactContent />
  </Layout>
)

export default ContactPage;
