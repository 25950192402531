import styled from 'styled-components';

export const Contact = styled.section`

    background-color: ${ props => props.theme.greyColor };

    .map {
        width: 100%;
        
        iframe {
            width: 100%;
            height: 20rem;
            border: none;
        }
    }

    .container {

        .contact-content {
            padding: 4rem 15rem;
            text-align: center;

            h2 {
                font-size: 2rem;
                margin-bottom: 2rem;
            }

            p {
                font-size: 1.3rem;
                margin-bottom: 1.4rem;

                a {
                    color: ${ props => props.theme.darkColor };
                    text-decoration: underline;
                }
            }

            p.reply-time {
                color: ${ props => props.theme.secondaryColor };
                font-size: 1rem;
            }

            h3 {
                margin: 1.8rem 0 0.5rem;
                font-size: 1.4rem;
                color: ${ props => props.theme.darkColor };
            }

            .social-icons {

                a{
                    font-size: 1.5rem;
                    color: ${ props => props.theme.primaryColor};
                }

            }
        }

    }

    @media (max-width: 768px){

        .container {

            .contact-content {
                padding: 4rem;
            }

        }

    }

    @media (max-width: 500px){

        .container {

            .contact-content {
                padding: 1rem;

                h2 {
                    font-size: 1.7rem;
                }
            }

        }

    }

`