import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Header from './../shared/Header';
import { Contact } from './../../elements/ContactElements.js';

const ContactContent = () => (
    <Contact>
        <Header page="Contact Prince Rathupa"/>
        <div className="container">
            <div className="contact-content">
                <h2>Let's work <span className="effect">together</span></h2>
                <p>If you have a website idea in mind or you need to improve your current website, feel free to contact me. The sooner you contact me, the better it is for both of us.</p>
                <p>If you want to collaborate with me in a project, you can check my <Link to="/about">tech stack here</Link></p>
                <p className="reply-time">Reply time: within 1 - 2 working days</p>
                <a href="mailto:hello@rathupap.co.za" rel="noreferrer" target="_blank" className="btn">hello@rathupap.co.za</a>
                <h3>Follow Me on social media</h3>
                <div className="social-icons">
                    <a href="https://twitter.com/ke_princie" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                    <a href="https://www.instagram.com/princie.js/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="https://linkedin.com/in/prince-rathupa-785725143/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                    <a href="https://github.com/rathupap/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faGithub} /></a>
                </div>
            </div>
        </div>
        <div className="map">
            <iframe
                frameBorder="0"
                title="Google Map"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDcywbf-L-Q_jihUJDazwba7Pc0gzg03Xo
                    &q=Johannesburg" allowFullScreen />
        </div>
    </Contact>
)

export default ContactContent;